<router-outlet></router-outlet>
<p-toast>
  <ng-template let-message pTemplate="message">
    @if (message.icon) {
      <span [class]="'p-toast-message-icon pi ' + message.icon"></span>
    } @else {
      <span class="p-toast-message-icon">
        @switch (message.severity) {
          @case ('success') {
            <span class="fas fa-check-circle"></span>
          }
          @case ('info') {
            <span class="fas fa-info-circle"></span>
          }
          @case ('error') {
            <span class="fas fa-times-circle"></span>
          }
          @case ('warn') {
            <span class="fas fa-exclamation-triangle"></span>
          }
        }
      </span>
    }

    <div class="p-toast-message-text">
      <div class="p-toast-summary">{{ message.summary }}</div>
      <div class="p-toast-detail" [innerHTML]="message.detail"></div>
    </div>
  </ng-template>
</p-toast>
<div class="loading-indicator" id="fullpage-loader" hidden>
  <p-progressSpinner></p-progressSpinner>
</div>
<app-confirm-dialog></app-confirm-dialog>
