import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { LoaderService } from './core/services/loader.service';
import { EventBusService } from './core/services/event-bus.service';
import { EventDataEnum } from './core/interfaces/event.interface';
import { NotificationMessage } from './core/interfaces/notification-message.interface';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageService } from './core/services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private translate = inject(TranslateService);
  private loader = inject(LoaderService);
  private eventBusService = inject(EventBusService);
  private messageService = inject(MessageService);
  private languageService = inject(LanguageService)

  private subs = new Subscription();
  constructor(private primengConfig: PrimeNGConfig, @Inject(DOCUMENT) private document: Document) {
    console.log(`App Version ${environment.version.version}: ${environment.version.commitHash}`);
    this.loadLang();
    this.initNotificationEvent();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  ngAfterViewInit(): void {
    const loadingIndicator = document.getElementById('fullpage-loader');
    if (loadingIndicator) {
      this.loader.setSpinnerContainer(loadingIndicator);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Inizializza le impostazioni di traduzione i18n
   * @param lang
   */
  private loadLang(): void {
    this.document.documentElement.lang = this.languageService.loadFromLocalStorage();
  }

  /**
   * Inizializza il funzionamento delle Notification Toast
   */
  private initNotificationEvent(): void {
    const notificationEvt = this.eventBusService.on(EventDataEnum.Notification, (value: NotificationMessage) => {
      const { message, messageInterpolateParams, type, title, titleInterpolateParams, duration, clear } = value;
      if (clear === true) {
        this.messageService.clear();
      }

      const detail: string = this.translate.instant(message, messageInterpolateParams) || '';
      const summary: string | undefined = title ? this.translate.instant(title, titleInterpolateParams) : undefined;
      const life = duration || 5000;

      this.messageService.add({ severity: type, summary, detail, life });
    });
    this.subs.add(notificationEvt);

    const notificationClearEvt = this.eventBusService.on(EventDataEnum.ClearNotification, () => {
      this.messageService.clear();
    });
    this.subs.add(notificationClearEvt);
  }
}
