import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { LanguageService } from '../../services/language.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [CommonModule, TranslateModule, DropdownModule, FormsModule],
  templateUrl: './language-switcher.component.html',
  styleUrl: './language-switcher.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent {
  protected availableLanguages = this.languageService.availableLanguages;
  protected currentLang$ = this.languageService.currentLang$.pipe(map(l => l?.code));

  constructor(private readonly languageService: LanguageService) {}

  public changeLanguage(evt: DropdownChangeEvent): void {
    this.languageService.changeLang(evt.value);
  }
}
