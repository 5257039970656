<p-confirmDialog #cd>
  @if (dialog(); as dialog) {
    <ng-template pTemplate="headless">
      <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
        @if (dialog.icon) {
          <div
            class="border-circle icon-bg inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8"
            [class.severity-success]="dialog.severity === 'success'"
            [class.severity-info]="dialog.severity === 'info'"
            [class.severity-warning]="dialog.severity === 'warning'"
            [class.severity-danger]="dialog.severity === 'danger'"
            [class.severity-help]="dialog.severity === 'help'">
            <i class="pi text-5xl" [class]="dialog.icon"></i>
          </div>
        }

        <span class="font-bold text-2xl block mb-2" [class.mt-4]="dialog.icon" [innerHTML]="dialog.title"></span>
        <p class="mb-0" [innerHTML]="dialog.message"></p>
        <div class="flex align-items-center gap-2 mt-4">
          @if (!dialog.hideConfirmButton) {
            <button
              pButton
              [label]="(dialog.cancelLabel ? dialog.cancelLabel : 'commons.no') | translate"
              (click)="cd.reject()"
              class="p-button-outlined w-8rem"></button>
            <button
              pButton
              [label]="(dialog.confirmLabel ? dialog.confirmLabel : 'commons.yes') | translate"
              (click)="cd.accept()"
              class="w-8rem"></button>
          } @else {
            <button pButton [label]="'commons.ok' | translate" (click)="cd.accept()" class="w-8rem"></button>
          }
        </div>
      </div>
    </ng-template>
  }
</p-confirmDialog>
