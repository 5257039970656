import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { canActivateAuthGuard, canActivateDashboardGuard, canActivateRoleGuard } from './core/guards/auth.guard';
import { IAMRoleEnum } from './core/models/role.model';
import { AppLayoutComponent } from './layout/app.layout.component';
import { ErrorComponent } from './public/error/error.component';
import { NotfoundComponent } from './public/notfound/notfound.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () => import('./public/landing/landing.module').then(m => m.LandingModule),
        },
        {
          path: 'callback/login',
          redirectTo: 'dashboard',
        },
        {
          path: 'callback/logout',
          redirectTo: '',
        },
        {
          path: 'legal/privacy-policy',
          loadComponent: () =>
            import('./public/legal/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
        },
        {
          path: 'legal/terms-of-service',
          loadComponent: () =>
            import('./public/legal/terms-of-service/terms-of-service.component').then(m => m.TermsOfServiceComponent),
        },
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: 'dashboard',
              loadComponent: () => import('./dashboard/dashboard.component').then(m => m.DashboardComponent),
              canActivate: [canActivateDashboardGuard],
            },
            {
              path: 'signup-requests',
              loadComponent: () =>
                import('./superadmin/signup-requests/signup-requests.component').then(m => m.SignupRequestsComponent),
              canActivate: [canActivateRoleGuard],
              data: {
                requiredRoles: [IAMRoleEnum.SUPERADMIN],
              },
            },
            {
              path: 'users',
              loadComponent: () => import('./superadmin/users/users.component').then(m => m.UsersComponent),
              canActivate: [canActivateRoleGuard],
              data: {
                requiredRoles: [IAMRoleEnum.SUPERADMIN],
              },
            },
            {
              path: 'sales-platforms',
              loadComponent: () =>
                import('./sales-platforms/sales-platforms.component').then(m => m.SalesPlatformsComponent),
              canActivate: [canActivateRoleGuard],
              data: {
                requiredRoles: [IAMRoleEnum.SUPERADMIN, IAMRoleEnum.SELLER],
              },
            },
            {
              path: 'sales-config-suppliers',
              loadComponent: () =>
                import('./sales-config-suppliers/sales-config-suppliers.component').then(
                  m => m.SalesConfigSuppliersComponent
                ),
              canActivate: [canActivateRoleGuard],
              data: {
                requiredRoles: [IAMRoleEnum.SUPERADMIN, IAMRoleEnum.SELLER],
              },
            },
            {
              path: 'suppliers',
              loadComponent: () => import('./suppliers/suppliers.component').then(m => m.SuppliersComponent),
              canActivate: [canActivateRoleGuard],
              data: {
                requiredRoles: [IAMRoleEnum.SUPERADMIN, IAMRoleEnum.SUPPLIER],
              },
            },
            {
              path: 'supplier-platform-links',
              loadChildren: () =>
                import('./supplier-platform-links/supplier-platform-links.routes').then(r => r.SUPPLIER_PLATFORM_LINKS),
              canActivate: [canActivateRoleGuard],
              data: {
                requiredRoles: [IAMRoleEnum.SUPERADMIN, IAMRoleEnum.SUPPLIER],
              },
            },
          ],
          canActivate: [canActivateAuthGuard],
        },
        { path: 'notfound', component: NotfoundComponent },
        { path: 'error', component: ErrorComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
