import { Component, inject } from '@angular/core';
import { map } from 'rxjs';
import { IAMRoleEnum } from '../core/models/role.model';
import { AuthenticationService } from '../core/services/authentication.service';
import { sellerRoutes, superadminRoutes, supplierRoutes } from './api/menu-routes';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent {
  layoutService = inject(LayoutService);
  authService = inject(AuthenticationService);

  model$ = this.authService.authenticationChanged.pipe(
    map(() => {
      const roles = this.authService.getCurrentUserRoles();
      if (roles.includes(IAMRoleEnum.SUPERADMIN)) {
        return superadminRoutes as any[];
      } else if (roles.includes(IAMRoleEnum.SELLER)) {
        return sellerRoutes as any[];
      } else if (roles.includes(IAMRoleEnum.SUPPLIER)) {
        return supplierRoutes as any[];
      } else {
        return [] as any[];
      }
    })
  );

  constructor() {}
}
