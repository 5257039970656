@if (availableLanguages) {
  <p-dropdown
    [options]="availableLanguages"
    [ngModel]="currentLang$ | async"
    optionLabel="label"
    optionValue="code"
    [placeholder]="'commons.change_language' | translate"
    styleClass="w-full"
    class="w-full"
    (onChange)="changeLanguage($event)" />
}
