import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpCache, provideHttpCacheLocalStorageStrategy, withHttpCacheInterceptor } from '@ngneat/cashew';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmDialogComponent } from './core/components/confirm-dialog/confirm-dialog.component';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { AuthenticationService } from './core/services/authentication.service';
import { StorageService } from './core/services/storage.service';
import { AppLayoutModule } from './layout/app.layout.module';
import { ErrorComponent } from './public/error/error.component';
import { NotfoundComponent } from './public/notfound/notfound.component';

// AoT requires an exported function for factories
export const HttpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
};

const authAppInitializerFactory = (authService: AuthenticationService): (() => Promise<void>) => {
  return () => authService.initAuthService();
};

@NgModule({
  declarations: [AppComponent, NotfoundComponent, ErrorComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    AppLayoutModule,
    BrowserModule,
    AppRoutingModule,
    ProgressSpinnerModule,
    ToastModule,
    ConfirmDialogComponent,
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [`${environment.hubBE}`],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: authAppInitializerFactory,
      multi: true,
      deps: [AuthenticationService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translateService: TranslateService) => {
        return () => {
          translateService.addLangs(['it', 'en']);
        };
      },
      multi: true,
      deps: [TranslateService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: AuthConfig,
      useValue: environment.authConfig,
    },
    {
      provide: OAuthStorage,
      useClass: StorageService,
    },
    provideHttpClient(withInterceptors([withHttpCacheInterceptor()])),
    provideHttpCache(),
    provideHttpCacheLocalStorageStrategy(),
    MessageService,
    ConfirmationService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
