import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from '../core/services/authentication.service';
import { LayoutService } from './service/app.layout.service';
import { LoaderService } from '../core/services/loader.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent {
  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  items!: MenuItem[];

  layoutService = inject(LayoutService);

  user$ = this.auth.getOIDCUser();

  private loader = inject(LoaderService);

  constructor(private auth: AuthenticationService) {}

  logout(): void {
    this.loader.showLoader();
    this.auth.signout();
  }
}
