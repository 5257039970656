/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { IAMRoleEnum } from '../models/role.model';
import { AuthenticationService } from '../services/authentication.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const canActivateAuth: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const isAuthenticated = authService.isAuthenticated$.getValue();
  if (!isAuthenticated) {
    return authService.authenticate();
  }
  return isAuthenticated;
};

const canActivateRole: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const roles = authService.getCurrentUserRoles();
  const requiredRoles: IAMRoleEnum[] = route.data['requiredRoles'] || [];

  return roles.some(b => requiredRoles.includes(b));
};

const canActivateDashboard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  const roles = authService.getCurrentUserRoles();

  if (roles.includes(IAMRoleEnum.SUPERADMIN)) {
    return true;
  } else if (roles.includes(IAMRoleEnum.SELLER)) {
    await router.navigate(['/sales-platforms']);
    return false;
  } else if (roles.includes(IAMRoleEnum.SUPPLIER)) {
    await router.navigate(['/suppliers']);
    return false;
  }
  return false;
};

export const canActivateRoleGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  canActivateRole(route, state);

export const canActivateAuthGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  canActivateAuth(route, state);

export const canActivateDashboardGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivateDashboard(route, state);
