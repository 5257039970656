/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerError } from '../interfaces/server-error.interface';
import { NotificationService } from '../services/notification.service';

export const AUTH_ERROR = 'AUTH_ERROR';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private notification = inject(NotificationService);
  // private translate = inject(TranslateService);
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        let notified = false;

        let errorMessage: any = '';
        if (err.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = err.error.message;
        } else if (err.error?.title && err.error.instance) {
          this.notification.error(err.error.title);
          notified = true;
        } else if (err.error?.timestamp && err.error.detail) {
          this.notification.error(err.error.detail);
          notified = true;
        } else if (err.error?.timestamp && err.error.error) {
          this.notification.error(err.error.error);
          notified = true;
        } else {
          // Get server-side error Type 2
          errorMessage = err;
        }

        const resultError = notified ? {} : errorMessage;
        return throwError(() => resultError);
      })
    );
  }

  /**
   * Se si tratta di un Errore ricevuto dal server, lo notifica con la rispettiva traduzione dall'ENUM
   *
   * @param serverError
   * @returns
   */
  private notifyError(serverError: ServerError): boolean {
    console.log(serverError);
    if (serverError != null && !!serverError.title) {
      this.notification.error(serverError.title);
      return true;
    }
    return false;
  }
}
