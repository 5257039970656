export const superadminRoutes = [
  {
    id: 0,
    label: 'commons.home',
    items: [
      { label: 'commons.dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/dashboard'] },
      { label: 'signup_requests.requests_management', icon: 'pi pi-fw pi-envelope', routerLink: ['/signup-requests'] },
      { label: 'users.users_management', icon: 'pi pi-fw pi-users', routerLink: ['/users'] },
      { label: 'sales_platforms.management', icon: 'pi pi-fw pi-server', routerLink: ['/sales-platforms'] },
      { label: 'suppliers.management', icon: 'pi pi-fw pi-gift', routerLink: ['/suppliers'] },
      {
        label: 'supplier_platform_links.management',
        icon: 'pi pi-fw pi-link',
        routerLink: ['/supplier-platform-links'],
        routerLinkActiveOptions: {
          paths: 'subset',
          queryParams: 'ignored',
          matrixParams: 'ignored',
          fragment: 'ignored',
        },
      },
      { label: 'sales_config_suppliers.management', icon: 'pi pi-fw pi-verified', routerLink: ['/sales-config-suppliers'] },
    ],
  },
];

export const supplierRoutes = [
  {
    id: 1,
    label: 'commons.home',
    items: [
      { label: 'suppliers.management', icon: 'pi pi-fw pi-gift', routerLink: ['/suppliers'] },
      {
        label: 'supplier_platform_links.management',
        icon: 'pi pi-fw pi-link',
        routerLink: ['/supplier-platform-links'],
        routerLinkActiveOptions: {
          paths: 'subset',
          queryParams: 'ignored',
          matrixParams: 'ignored',
          fragment: 'ignored',
        },
      },
    ],
  },
];

export const sellerRoutes = [
  {
    id: 2,
    label: 'commons.home',
    items: [
      { label: 'sales_platforms.management', icon: 'pi pi-fw pi-server', routerLink: ['/sales-platforms'] },
      { label: 'sales_config_suppliers.management', icon: 'pi pi-fw pi-verified', routerLink: ['/sales-config-suppliers'] },
    ],
  },
];
