import { Injectable, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, map, startWith, tap } from 'rxjs';
import { AvailableLanguage } from '../components/language-switcher/language-switcher.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService implements OnDestroy {
  public currentLang$ = new BehaviorSubject<AvailableLanguage | undefined>(undefined);
  public availableLanguages: AvailableLanguage[] = [];
  private readonly translateService = inject(TranslateService);
  private static localStorageKey = 'current-lang';
  private sub = new Subscription();

  constructor() {
    this.availableLanguages = this.translateService.getLangs().map(lang => ({
      code: lang,
      label: lang,
      ...(lang === 'it' && { label: 'ITA' }),
      ...(lang === 'en' && { label: 'ENG' }),
    }));

    const sub = this.translateService.onLangChange
      .pipe(
        map(event => this.availableLanguages?.find(l => l.code === event.lang)),
        tap(lang => this.saveToLocalStorage(lang))
      )
      .subscribe();

    this.sub.add(sub);
  }

  changeLang(code: string) {
    this.translateService.use(code);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public loadFromLocalStorage(): string {
    const defaultLang = 'it';
    this.translateService.setDefaultLang(defaultLang);
    const current = localStorage.getItem(LanguageService.localStorageKey) || defaultLang;
    if (this.availableLanguages?.find(l => l.code === current)) {
      this.translateService.use(current);
    } else {
      this.translateService.use(defaultLang);
    }
    return current;
  }

  private saveToLocalStorage(lang?: AvailableLanguage) {
    if (lang) {
      this.currentLang$.next(lang);
      localStorage.setItem(LanguageService.localStorageKey, lang.code);
    }
  }
}
