<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img
      src="assets/layout/images/{{ layoutService.config().colorScheme === 'light' ? 'logo-black' : 'logo-white' }}.svg"
      alt="logo" />
  </a>

  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
    <i class="pi pi-bars"></i>
  </button>  

  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="op.toggle($event)">
    <i class="pi pi-user"></i>
  </button>

  <div
    #topbarmenu
    class="layout-topbar-menu"
    [ngClass]="{ 'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible }">
    <button class="p-link layout-topbar-button" (click)="op.toggle($event)">
      <i class="pi pi-user"></i>
    </button>
  </div>
</div>

<p-overlayPanel #op appendTo="body">
  <ng-template pTemplate>
    <div class="grid" style="max-width: 200px">
      <div class="col-12 flex justify-content-start align-items-center">
        <p-avatar icon="pi pi-user" shape="circle"></p-avatar>
        @if (user$ | async; as user) {
          <div class="ml-3" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
            <span class="font-bold text-lg">{{ user?.info?.preferred_username }}</span>
          </div>
        }
      </div>
      <div class="p-2 w-full">
        <app-language-switcher></app-language-switcher>
      </div>
      <hr />
      <div class="col-12 flex justify-content-start align-items-center">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-sign-out"
          [label]="'commons.logout' | translate"
          (click)="logout()"
          class="p-button-danger p-button-text w-full"></button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
